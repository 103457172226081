<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE PURCHASE ORDER DETAILS</title>
    <section class="content-header">
      <h1>
        Update Purchase Order Details
        <br />
        <h4>Please edit the purchase order transaction data for details</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Transaction Purchase Order Details</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
            <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Purchase Order Details</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">PO Number</label>
                  <input
                    type="text"
                    v-model="nomerpod"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idxpod"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Item Code</label>
                    <input
                      type="text"
                      v-model="kodebarang"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Item Code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getbarang()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Name </label>
                  <input
                    type="text"
                    readonly
                    v-model="namabarang"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="number"
                    v-model="qtyd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit</label>
                  <input
                    type="text"
                    v-model="unitd"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Price</label>
                  <input
                    type="number"
                    v-model="priced"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit Price</label>
                  <input
                    type="text"
                    v-model="unitpriced"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="notespod"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/po">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
       
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "updtdt",
  data() {
    return {
      loading: false,
      nomerpod: "",
      idxpod: "",
      kodebarang: "",
      namabarang: "",
      qtyd: "",
      unitd: "",
      priced: "",
      unitpriced: "",
      notespod: ""
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    getbarang() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getbarangbykode_barang?kode_barang=" + this.kodebarang;
      const urlAPIget =
        this.$apiurl +
        "barang/getbarangbykode_barang?kode_barang=" +
        this.kodebarang;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Item Code not found",
              showConfirmButton: false
            });
          } else {
            this.kodebarang = resp.data.data.kode_barang;
            this.namabarang = resp.data.data.nama_barang;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getbarangbyid?id="+this.$route.params.id;
      const urlAPIget =
        this.$apiurl + "po_detail/getpo_detailbyid?id=" + this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idxpod = resp.data.data.id;
          this.nomerpod = resp.data.data.po_number;
          this.kodebarang = resp.data.data.kode_barang;
          this.qtyd = resp.data.data.qty;
          this.unitd = resp.data.data.unit;
          this.priced = resp.data.data.price;
          this.unitpriced = resp.data.data.unit_price;
          this.notespod = resp.data.data.notes;

          const urlAPIgetsup =
            this.$apiurl +
            "barang/getbarangbykode_barang?kode_barang=" +
            this.kodebarang;

          axios.get(urlAPIgetsup, { headers }).then((respo) => {
            if (respo.data.pesan == "Data not found") {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Sorry, Item Code not found",
                showConfirmButton: false
              });
            } else {
              this.namabarang = respo.data.data.nama_barang;
            }
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var kode = this.nomerpod;
      var kodebrg = this.kodebarang;
      var namabrg = this.namabarang;
      var qtydd = this.qtyd;
      var unitdd = this.unitd;
      var harga = this.priced;
      var unitpricedd = this.unitpriced;
      var catatanpo = this.notespod;
      var idx = this.idxpod;
      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "PO Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kodebrg == "" || namabrg == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Item Code / Item Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qtydd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (unitdd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (harga == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Price can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (unitpricedd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit Price can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatanpo == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes PO Detail can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          po_number: this.nomerpod,
          kode_barang: this.kodebarang,
          notes: this.notespod,
          qty: this.qtyd,
          unit: this.unitd,
          price: this.priced,
          unit_price: this.unitpriced,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIUpdate = "http://26.183.23.191/inventory/backend/inventory/api/po_detail/updatepo_detail/"+ idx;
        const urlAPIUpdate = this.$apiurl + "po_detail/updatepo_detail/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              window.location.href="/po"
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
